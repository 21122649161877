import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { GOOGLE_IMG } from '../../config/constants/images';
import COOKIES from '../../config/cookies/cookies';
import { SIGN_IN_WITH_GOOGLE } from '../../config/text/text';
import { setCookie } from '../../helpers/cookieSetter';
import { loadGoogleScript, gapiInit } from '../../helpers/googleService';
import { loginUser } from '../../helpers/googleServiceInternal';
import useToast from '../_generic/toast/UseToast';
import MediaButton from './MediaButton';

const GoogleButton = ({
  title = SIGN_IN_WITH_GOOGLE,
  registration = false,
  subscriberPage = false,
  isBadConnection = false,
  isLoginPage = false,
  checkout = false,
}) => {
  const toast = useToast();
  const dispatch = useDispatch();

  const googleInit = () => {
    const googleLogin = (authTwo) => {
      const element = document.querySelector('#googleButton');

      const buttonClick = (googleUser) => {
        // The ID token you need to pass to your backend:
        const token = googleUser.getAuthResponse().id_token;

        const profile = googleUser.getBasicProfile();
        const email = profile.getEmail();

        loginUser({
          authTwo,
          dispatch,
          email,
          isLoginPage,
          registration,
          toast,
          token,
        });

        setCookie({
          expire: 365,
          key: COOKIES.userEmail,
          value: email,
        });
      };
      authTwo.attachClickHandler(element, {}, buttonClick);
    };

    gapiInit(googleLogin);
  };

  useEffect(() => {
    loadGoogleScript(googleInit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <MediaButton img={GOOGLE_IMG} title={title} type="google" />
    </>
  );
};

GoogleButton.propTypes = {
  checkout: PropTypes.bool,
  isBadConnection: PropTypes.bool,
  isLoginPage: PropTypes.bool,
  registration: PropTypes.bool,
  subscriberPage: PropTypes.bool,
  title: PropTypes.string,
};

export default GoogleButton;
