import cookies from 'react-cookies';
import COOKIES from '../config/cookies/cookies';
import {
  COOKIE_SUBSCRIBED_EXPIRES,
  COOKIES_EXPIRES_DAY,
} from '../config/setup/setup';
import {
  AN_ERROR_OCCURED,
  INVALID_GOOGLE_TOKEN_ID,
  SUBSCRIPTION_FAIL,
} from '../config/text/text';
import { setLoadingState } from '../redux/actions/loading';
import { getProfile } from '../redux/actions/user';
import { LOADING_STATUS } from '../redux/reducers/loading';
import { trackEvent } from './analytics';
import commonCookiesOptions from './commonCookiesOptions';
import { setCookie } from './cookieSetter';
import { logoutGoogle } from './googleService';
import {
  loginGoogleUser,
  registerGoogleUser,
  showErrorToast,
} from './googleServiceAPI';
import { registerSponsoredSearchSessionId } from './sponsoredSearch';
import { subscribeUser } from './subscribe';
import { unsubscribeAllUser } from './unsubscribe';

const handleSubscription = async ({ isNewEmail, registration }) => {
  if (registration) {
    trackEvent('completed_registration');
  } else {
    trackEvent('completed_subscription');
  }
  if (isNewEmail) {
    trackEvent('newEmailEvent');
  } else {
    trackEvent('repeatEmailEvent');
  }
};

export const subscribeGoogleUser = async (
  email,
  registration,
  isNewEmail = false,
) => {
  try {
    await subscribeUser(email);
    handleSubscription({ isNewEmail, registration });
  } catch {
    console.error(SUBSCRIPTION_FAIL);
  }
};

export const onGoogleLoginSuccess = async (authTwo, dispatch) => {
  cookies.save(COOKIES.loginType, 'google_user', {
    ...commonCookiesOptions,
    COOKIES_EXPIRES_DAY,
  });
  // Remove referral friend cookie if it is already there
  if (cookies.load(COOKIES.referralCode)) {
    cookies.remove(COOKIES.referralCode);
  }
  await registerSponsoredSearchSessionId();

  trackEvent('google_sign_in');
  trackEvent('log_in');

  dispatch(getProfile());
  getProfile();

  dispatch(setLoadingState(LOADING_STATUS.READY));

  logoutGoogle(authTwo);
};

export const registerUser = async ({
  authTwo,
  dispatch,
  email,
  registration,
  toast,
  token,
}) => {
  try {
    const response = await registerGoogleUser(token);

    if (response.data) {
      onGoogleLoginSuccess(authTwo, dispatch);
    } else {
      dispatch(setLoadingState(LOADING_STATUS.READY));
      showErrorToast(response.message || AN_ERROR_OCCURED, toast);
    }
  } catch (error) {
    dispatch(setLoadingState(LOADING_STATUS.READY));
    showErrorToast(error.message || AN_ERROR_OCCURED, toast);
  }
};

export const loginUser = async ({
  authTwo,
  dispatch,
  email,
  isLoginPage,
  registration,
  toast,
  token,
}) => {
  if (cookies.load(COOKIES.userInfo)) {
    cookies.remove(COOKIES.userInfo, {
      domain: `.${process.env.NEXT_PUBLIC_DOMAIN_NAME}`,
      path: '/',
    });
  }
  dispatch(setLoadingState(LOADING_STATUS.LOADING));

  try {
    const response = await loginGoogleUser(token);
    const responseCode = response.response && response.response.code;

    if (responseCode === '2000') {
      cookies.save(
        COOKIES.customerToken,
        response.response.data.customerToken,
        {
          ...commonCookiesOptions,
          COOKIE_SUBSCRIBED_EXPIRES,
        },
      );
      // if emailOpt is true we need to call unsubscribe from all emails endpoint
      const emailOptOut = cookies.load(COOKIES.emailOptOut) === 'true';
      if (emailOptOut && response?.response?.data?.customerToken) {
        await unsubscribeAllUser(response.response.data.customerToken);
      }
      onGoogleLoginSuccess(authTwo, dispatch);
    } else if (responseCode === '2002') {
      // register on login page, set cookie so user is not subscribed
      if (isLoginPage) setCookie({ key: COOKIES.emailOptOut, value: true });

      registerUser({ authTwo, dispatch, email, registration, toast, token });
    } else if (responseCode === '2001') {
      dispatch(setLoadingState(LOADING_STATUS.READY));
      showErrorToast(
        response.response.message || INVALID_GOOGLE_TOKEN_ID,
        toast,
      );
    } else {
      dispatch(setLoadingState(LOADING_STATUS.READY));
      showErrorToast(AN_ERROR_OCCURED, toast);
    }
  } catch (error) {
    dispatch(setLoadingState(LOADING_STATUS.READY));
    showErrorToast(error.message || AN_ERROR_OCCURED, toast);
  }
};
