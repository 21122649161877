/* eslint-disable filenames/match-regex */
import Cookies from 'react-cookies';
import axios from '../components/_generic/axiosSplunk/axiosSplunk';
import COOKIES from '../config/cookies/cookies';
import { URLGOOGLELOGIN, URLGOOGLEREGISTR } from '../config/setup/setup';
import httpCommonHeaders from './httpCommonHeaders';
import { getUserLocationShortName } from './user';

export const loginGoogleUser = async (token) => {
  const { data } = await axios({
    cache: false,
    data: {
      loginRequest: {
        tokenId: token,
      },
    },
    headers: httpCommonHeaders(),
    method: 'POST',
    url: URLGOOGLELOGIN,
    validateStatus: (status) => {
      return status < 500;
    },
    withCredentials: true,
  });

  return data;
};

export const registerGoogleUser = async (token) => {
  const { data } = await axios({
    cache: false,
    data: {
      requestRegistration: {
        emailOptOut: Cookies.load(COOKIES.emailOptOut) === 'true',
        location: getUserLocationShortName(),
        referralCode: Cookies.load(COOKIES.referralCode),
        subscriptionSource: Cookies.load(COOKIES.userSource) || '',
        tokenId: token,
      },
    },
    headers: httpCommonHeaders(),
    method: 'POST',
    url: URLGOOGLEREGISTR,
    validateStatus: (status) => {
      return status < 500;
    },
    withCredentials: true,
  });

  return data;
};

export const showErrorToast = (message, toast) => {
  toast.addToast(message, 'toast-error');
};
