import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import ThemeContext from '../../providers/ThemeProvider';

const VoucherTip = ({ children, isKlarnaMessage = null }) => {
  const theme = useContext(ThemeContext);

  return (
    <>
      <span className="tooltip-container">
        <FontAwesomeIcon
          color={theme.colors.buttonprimary}
          data-testid="tip-icon"
          icon={faInfoCircle}
        />
        <span className="tooltip" role="tooltip">
          {children}
        </span>
      </span>
      <style jsx>{`
        .tooltip-container {
          cursor: pointer;
          font-size: ${isKlarnaMessage ? '20px' : '14px'};
          left: ${isKlarnaMessage ? '95%' : '0'};
          margin-left: 6px;
          position: relative;
          cursor: pointer;
          z-index: 1;
        }
        .tooltip {
          display: none;
          position: absolute;
          top: 26px;
          left: -93px;
          width: 200px;
          background-color: ${theme.colors.buttonprimary};
          padding: 8px;
          border-radius: 4px;
          box-shadow: 0 0 4px #aaa;
          color: #fff;
          display: none;
          font-size: 14px;
          left: -93px;
          padding: 8px;
          position: absolute;
          text-align: center;
          top: 26px;
          width: 200px;
        }
        .tooltip:before {
          border-bottom: 8px solid ${theme.colors.buttonprimary};
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          content: '';
          height: 0;
          left: 93px;
          position: absolute;
          top: -8px;
          left: 93px;
          content: '';
          width: 0;
          height: 0;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-bottom: 8px solid ${theme.colors.buttonprimary};
          z-index: 1;
        }
        .tooltip-container:hover .tooltip,
        .tooltip-container:focus .tooltip {
          display: block;
        }
      `}</style>
    </>
  );
};
VoucherTip.propTypes = {
  children: PropTypes.any,
  isKlarnaMessage: PropTypes.bool,
};
export default VoucherTip;
