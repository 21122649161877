import PropTypes from 'prop-types';
import React from 'react';

const TextDevider = ({ vipPaywall = false }) => {
  return (
    <>
      <div className="text-divider text-divider--or" />
      <style jsx>{`
        .text-divider {
          position: relative;
          margin: 15px 0;
          text-align: center;
          color: ${vipPaywall ? 'black' : ''};
        }
        .text-divider--or::after {
          content: 'or';
          background-color: #f5f5f5;
          border: 1px solid #dbdbdb;
          padding: 0 12px;
          border-radius: 3px;
          font-size: 12px;
          position: relative;
          z-index: 2;
        }
        .text-divider::before {
          content: '';
          border-top: 1px solid #dbdbdb;
          display: block;
          width: 100%;
          position: absolute;
          top: 50%;
          z-index: 1;
        }
      `}</style>
    </>
  );
};

TextDevider.propTypes = {
  vipPaywall: PropTypes.bool,
};
export default TextDevider;
