import axios from '../components/_generic/axiosSplunk/axiosSplunk';
import { URLSUBSCRIBE, URLSUBSCRIBEALL } from '../config/setup/setup';
import httpCommonHeaders from './httpCommonHeaders';

export const unsubscribeUser = async (authToken) => {
  const { data } = await axios({
    cache: false,
    data: {
      locationAuthToken: authToken,
    },
    headers: httpCommonHeaders(),
    method: 'DELETE',
    url: URLSUBSCRIBE,
    validateStatus: (status) => {
      return status < 500;
    },
    withCredentials: true,
  });

  return data;
};

export const unsubscribeAllUser = async (customerToken) => {
  const { data } = await axios({
    cache: false,
    data: {
      customerToken,
    },
    headers: httpCommonHeaders(),
    method: 'DELETE',
    url: URLSUBSCRIBEALL,
    validateStatus: (status) => {
      return status < 500;
    },
    withCredentials: true,
  });

  return data;
};
