import {
  faInfoCircle,
  faCheckCircle,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import ThemeContext from '../../providers/ThemeProvider';
import VoucherTip from '../VoucherItem/VoucherTip';

const INFO_TYPE = 'INFO';
const SUCCESS_TYPE = 'SUCCESS';
const ERROR_TYPE = 'ERROR';

const MESSAGE_TYPES = {
  error: ERROR_TYPE,
  info: INFO_TYPE,
  success: SUCCESS_TYPE,
};
export { MESSAGE_TYPES };

const Message = ({
  extraClasses,
  hoverMessage = false,
  html,
  iconPosition,
  message,
  showIcon,
  type,
  zIndex,
}) => {
  const theme = useContext(ThemeContext);
  const zIndexValue = !isNaN(zIndex) ? zIndex : 10;

  let messageClass = 'message message--is-info';
  let faIcon = faInfoCircle;
  if (type === MESSAGE_TYPES.success) {
    messageClass = 'message message--is-success';
    faIcon = faCheckCircle;
  }
  if (type === MESSAGE_TYPES.error) {
    messageClass = 'message message--is-error';
    faIcon = faExclamationTriangle;
  }
  messageClass += ` ${extraClasses}`;
  messageClass += showIcon ? `message-icon--${iconPosition}` : '';

  const textMessage = html ? (
    <span dangerouslySetInnerHTML={{ __html: message }} />
  ) : (
    message
  );

  return (
    <>
      <div className={messageClass}>
        {showIcon && (
          <>
            {!hoverMessage && (
              <FontAwesomeIcon
                className={`icon icon--${iconPosition}`}
                icon={faIcon}
              />
            )}
            {hoverMessage && (
              <VoucherTip isKlarnaMessage>{hoverMessage}</VoucherTip>
            )}
          </>
        )}
        {textMessage}
      </div>
      <style jsx>{`
        .message {
          border: 1px solid ${theme.colors.infoboxborder};
          color: ${theme.colors.infoboxtext};
          position: relative;
          margin: 0 0 15px 0;
          padding: 12px 16px;
          z-index: ${zIndexValue};
        }
        .message.message-icon--${iconPosition} {
          padding-${iconPosition}: 50px;
        }
        .message--is-success {
          border-color: ${theme.colors.successboxborder};
          background: ${theme.colors.successboxbg};
          color: ${theme.colors.successboxtext};
        }
        .message--is-info {
          border-color: ${theme.colors.infoboxborder};
          background: ${theme.colors.infoboxbg};
          color: ${theme.colors.infoboxtext};
        }
        .message--is-error {
          border: 1px solid ${theme.colors.errorboxborder};
          font-weight: bold;
          background: ${theme.colors.errorboxbg};
          color: ${theme.commoncolors.alert};
        }
        .message-no-background {
          background: none;
        }
        .message-center {
          text-align: center;
        }
        .message-border-radius {
          border-radius: 5px;
        }
        :global(.icon) {
          top: 12px;
          position: absolute;
          font-size: 20px;
        }
        :global(.icon.icon--${iconPosition}) {
          ${iconPosition}: 16px;
        }
        :global(.message--is-success .icon) {
          color: ${theme.colors.successboxborder};
        }
        :global(.message--is-info .icon) {
          color: ${theme.colors.infoboxborder};
        }
        :global(.message--is-error .icon) {
          color: ${theme.colors.errorboxborder};
        }
        :global(.message--is-error a) {
          color: ${theme.colors.errorboxtext};
        } 
        .message--is-info.orange-theme {
          background: #e9ba26;
          border-color: transparent;
          border-bottom: 1px solid #7e620d;
          color: #000;
          text-shadow: 0 1px 0 rgb(255 255 255 / 20%);
          box-shadow: inset 0 1px 0 rgb(255 255 255 / 25%), 0 1px 2px rgb(0 0 0 / 5%);
          margin: 0;
        }

        .message--is-info.green-theme {
          background: ${theme.colors.successgreen};
          border: 1px solid ${theme.colors.sucessgreenborder};
          color: #000;
        }
        .message--is-info.yellow-theme {
          border: 1px solid ${theme.colors.infoyellow};
          background: ${theme.colors.infoyellowbg};
          color: ${theme.colors.infoyellow};
        }
        :global(.message--is-info.yellow-theme .icon) {
          color: ${theme.colors.infoyellow};
        }
      `}</style>
    </>
  );
};

Message.propTypes = {
  extraClasses: PropTypes.string,
  hoverMessage: PropTypes.bool,
  html: PropTypes.bool,
  iconPosition: PropTypes.oneOf(['left', 'right']),
  message: PropTypes.string.isRequired,
  showIcon: PropTypes.bool,
  type: PropTypes.string,
  zIndex: PropTypes.number,
};

Message.defaultProps = {
  extraClasses: '',
  html: false,
  iconPosition: 'right',
  showIcon: true,
};

export default Message;
